<template>
<div class="container p-5">
  <weather-app></weather-app>
</div>

</template>

<script>
import WeatherApp from './components/WeatherAppSPA.vue'

export default {
  components: {
    WeatherApp
  }
}
</script>

<style>

</style>
